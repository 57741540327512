.items-container{
    display: flex;
    margin: auto;
    padding: 1%;
}
.menu-container {
    margin: 0 0 5em 0;
}
.left-col {
    width: 90%;
    border-bottom: solid 1px black;
}
.right-col {
    width: 10%;
    border-bottom: solid 1px black;
    margin: 3em 0 0 0;
    text-align:right;
}
.menu-title {
    width: 50%;
    margin: auto;
}
.menu-content h1 {
    margin: 3em 0 2em 0;
    text-align: center;
    font-family: 'Montez', cursive;
    font-size: 42px;
    text-decoration: underline rgb(196, 162, 117);
}
.section-title {
    margin: 2em 0 1em 0;
    font-family: 'Playfair Display', serif;
    color:rgb(196, 162, 117);
}
h3 {
    font-family: 'Playfair Display', serif;
}

@media only screen and (max-width: 1060px) {
    .menu-wrapper {
        margin:0;
        border: solid 1px rgba(138, 15, 36, 0);
    }
}

@media only screen and (max-width: 685px) {
    .right-col {
        width: 10%;
        border-bottom: solid 1px black;
        margin: 3em 0 0 0;
        text-align:right;
    }
}