@import url(https://fonts.googleapis.com/css2?family=Raleway&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montez&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway&display=swap);
.nav-menu {
  font-family: "Playfair Display", serif;
  background-color: white;
  width: 250px;
  position: fixed;
  height: 100vh;
  left: 0;
  transition: 350ms;
  z-index: 998;
}

.nav-menu a {
  text-decoration: none;
  color: #c4a275;
}

.nav-menu a:focus {
  outline: 1px solid blue;
  outline-offset: 5px;
}

.nav-menu a:focus-visible {
  outline: 1px solid blue;
  outline-offset: 5px;
}

.nav-menu a:focus:not(:focus-visible) {
  outline: none;
}

.nav-menu-active {
  font-family: "Playfair Display", serif;
  position: fixed;
  left: -100%;
  width: 250px;
  transition: 850ms;
  height: 100vh;
  z-index: 999;
}

.nav-menu-active a {
  text-decoration: none;
  color: #c4a275;
}

.nav-menu-active a:focus {
  outline: 1px solid blue;
  outline-offset: 5px;
}

.nav-menu-active a:focus-visible {
  outline: 1px solid blue;
  outline-offset: 5px;
}

.nav-menu-active a:focus:not(:focus-visible) {
  outline: none;
}

.nav-menu-active ul li {
  margin: 0.5em 0 0 0;
  text-align: left;
  padding: 10px;
  font-size: 1.4em;
  list-style: none;
}

nav ul {
  margin-top: 4em;
}

nav ul li {
  margin: 0.5em 0 0 0;
  text-align: left;
  padding: 10px;
  font-size: 1.4em;
  list-style: none;
}

nav ul li a:hover {
  font-weight: bold;
}

nav ul .shop {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 10%;
  display: flex;
  justify-content: center;
  background-color: #0b0e30;
}

nav ul .shop:hover {
  background-color: #010e7e;
}

nav ul .shopicon {
  margin: auto;
  padding-right: 5px;
  color: white;
}

nav ul .shopcount {
  color: white;
}

.menu-btn {
  cursor: pointer;
  padding: 1.4%;
  margin-top: 10px;
  position: fixed;
  z-index: 999;
  color: #cfcfcf;
  background-color: transparent;
  border: transparent;
  outline: 0;
}

.menu-btn:hover {
  color: #c4a275;
}

.logo {
  display: none;
  width: 20%;
  position: relative;
  color: #0b0e30;
}

.logo a:focus {
  outline: 1px solid blue;
  outline-offset: 5px;
}

.logo a:focus-visible {
  outline: 1px solid blue;
  outline-offset: 5px;
}

.logo a:focus:not(:focus-visible) {
  outline: none;
}

@media only screen and (min-width: 1060px) {
  .nav-container {
    position: relative;
    display: flex;
    padding: 1%;
    z-index: 999;
    border-bottom: solid 1px #dbdbdb;
  }
  .logo {
    display: unset;
    font-size: 2.5em;
    font-family: "Montez", cursive;
    margin: auto 2% auto 2%;
    position: relative;
    width: 200px;
    text-decoration: none;
  }
  .logo-txt {
    color: #0b0e30;
    text-decoration: none;
  }
  .logo-txt:focus {
    outline: 1px solid #0003ce;
    outline-offset: 5px;
  }
  .menu-btn {
    display: none;
  }
  .nav-menu {
    position: unset;
    margin-left: auto;
    width: 80%;
    height: auto;
    transition: 0ms;
  }
  .nav-menu ul {
    margin: auto;
    margin-top: unset;
    display: flex;
    justify-content: right;
  }
  .nav-menu li {
    font-size: 1.26em;
    margin: 0 1em 0 1em;
  }
  .nav-menu li a:hover {
    font-weight: unset;
    color: rgba(196, 162, 117, 0.74);
  }
  .nav-menu li:focus {
    outline: 1px solid #0003ce;
    outline-offset: 5px;
  }
  .nav-menu .shop {
    width: 4%;
    border-radius: 10%;
    display: flex;
    justify-content: center;
    background-color: #0b0e30;
  }
  .nav-menu .shop:hover {
    background-color: #010e7e;
  }
  .nav-menu .shopicon {
    margin: auto;
    padding-right: 5px;
    color: white;
  }
  .nav-menu .shopcount {
    color: white;
  }
  .nav-menu-active {
    position: unset;
    margin-left: auto;
    width: 80%;
    height: auto;
    transition: 0ms;
  }
  .nav-menu-active ul {
    margin: auto;
    margin-top: unset;
    display: flex;
    justify-content: right;
  }
  .nav-menu-active ul li {
    font-size: 1.26em;
    margin: 0 1em 0 1em;
  }
  .nav-menu-active ul li a:hover {
    font-weight: unset;
    color: rgba(196, 162, 117, 0.74);
  }
  .nav-menu-active ul li:focus {
    outline: 1px solid #0003ce;
    outline-offset: 5px;
  }
  .nav-menu-active ul .shop {
    width: 4%;
    border-radius: 10%;
    display: flex;
    justify-content: center;
    background-color: #0b0e30;
  }
  .nav-menu-active ul .shop:hover {
    background-color: #010e7e;
  }
  .nav-menu-active ul .shopicon {
    margin: auto;
    padding-right: 5px;
    color: white;
  }
  .nav-menu-active ul .shopcount {
    color: white;
  }
}
/*# sourceMappingURL=Nav.css.map */
.cart-wrapper {
  animation-name: cartshow;
  animation-duration: 0.2s;
}

@media only screen and (min-width: 1200px) {
  .cart-wrapper {
    width: 100%;
    position: relative;
  }
}

@keyframes cartshow {
  from {
    opacity: 50%;
  }
  to {
    opacity: 100%;
  }
}

.checkout-btn {
  width: 60%;
  text-decoration: none;
  margin-left: 2%;
  margin-top: 2%;
  padding-right: 2%;
}
/*# sourceMappingURL=popupcart.css.map */
.fill-screen {
    min-height: 100vh;
    max-width: 100vw;
}
.about-section .img-wrap {
  width: 50%;
}

.about-section .img-wrap img {
  width: 100%;
  height: 100%;
}

.about-section {
  display: flex;
  justify-content: space-between;
}

.text {
  color: black;
  font-size: 20px;
  width: 44%;
  padding: 3%;
  text-align: left;
  /*Add white-space and overflow-wrap to prevent text from escaping container*/
  white-space: pre-wrap;
  overflow-wrap: break-word;
  font-family: "Playfair Display", serif;
  margin: auto;
}

.about-section p {
  border-top: 1px solid #0b0e30;
  border-bottom: 1px solid rgba(54, 63, 158, 0.404);
  padding: 3%;
}

@media only screen and (max-width: 1350px) {
  .about-section .img-wrap {
    width: 100%;
  }
  .about-section .img-wrap img {
    width: 100%;
  }
  .text {
    width: 100%;
  }
  .text h2 {
    font-size: 22px;
  }
  .text p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1050px) {
  .about-section {
    display: block;
  }
  .text {
    max-width: 90vw;
  }
}
/*# sourceMappingURL=about.css.map */
.wrapper_contact {
  background-color: #c4a275;
  padding: 2%;
  font-family: "Raleway", sans-serif;
}

section {
  width: 80%;
  display: flex;
  margin: auto;
  justify-content: space-between;
}

section h2 {
  margin: 10px 0px 20px 1.6em;
}

.contact-info {
  /*Add white-space and overflow-wrap to prevent text from escaping container*/
  white-space: pre-wrap;
  overflow-wrap: break-word;
  margin: auto;
  margin: 0px 0px 0px 25px;
}

.text-wrap-mid {
  border-left: 1px solid black;
  width: 25%;
}

.text-wrap {
  width: 25%;
}

.form-wrap {
  width: 100%;
}

.social-contact {
  width: 100%;
}

.social-contact a {
  margin: 0 2em 0 1em;
  color: black;
}

.twitter:hover, .twitter:focus {
  margin: 0 2em 0 1em;
  color: #0096d1;
  outline-offset: 3px;
}

.insta:hover, .insta:focus {
  margin: 0 2em 0 1em;
  color: #9b0dad;
  outline-offset: 3px;
}

.facebook:hover, .facebook:focus {
  margin: 0 2em 0 1em;
  color: #080875;
  outline-offset: 3px;
}

.text-wrap li {
  list-style: none;
  margin: 0 0 1em 0;
  font-size: 21px;
}

.text-wrap-mid li {
  list-style: none;
  margin: 0 0 1em 0;
  font-size: 21px;
}

.text-wrap-mid form {
  margin: 0 0 0 0;
  font-size: 18px;
}

h2 {
  color: black;
}

@media only screen and (max-width: 1560px) {
  .text-wrap {
    margin: 0;
  }
  .text-wrap h2 {
    font-size: 20px;
  }
  .text-wrap ul li {
    font-size: 18px;
  }
  .text-wrap-mid h2 {
    font-size: 20px;
  }
  .text-wrap-mid ul li {
    font-size: 15px;
  }
  .wrapper_contact section {
    width: 100%;
  }
}

@media only screen and (max-width: 945px) {
  .wrapper_contact section {
    width: 100%;
  }
  .wrapper_contact .text-wrap ul li {
    font-size: 14px;
  }
  .wrapper_contact .text-wrap-mid ul li {
    font-size: 10px;
  }
}

@media only screen and (max-width: 685px) {
  .wrapper_contact section {
    display: block;
  }
  .wrapper_contact .text-wrap {
    width: 100%;
    text-align: center;
    border-bottom: solid 1px black;
  }
  .wrapper_contact .text-wrap-mid {
    width: 100%;
    text-align: center;
    border-bottom: solid 1px black;
    border-left: unset;
  }
  .wrapper_contact .text-wrap-mid ul li {
    font-size: 15px;
  }
}
/*# sourceMappingURL=contactbar.css.map */
form {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.281);
}

.reserve {
  margin: auto;
  width: 80%;
  max-width: 200px;
  padding: 5%;
  background-color: white;
  border-radius: 2%;
}

input {
  width: 97%;
  height: 2em;
  background-color: white;
  border: solid 1px white;
  border-bottom: solid 1px rgba(0, 0, 0, 0.281);
}

input:focus {
  background-color: rgba(209, 209, 209, 0.61);
}

button[type="submit"] {
  width: 100%;
  background-color: #0b0e30;
  color: white;
  height: 2em;
  border: 1px solid #0b0e30;
}

button[type="submit"]:hover, button[type="submit"]:focus {
  background-color: #010e7e;
}

select {
  width: 100%;
  height: 2em;
  background-color: white;
  border: solid 1px white;
  border-bottom: solid 1px rgba(0, 0, 0, 0.281);
}

select:focus {
  background-color: rgba(209, 209, 209, 0.61);
}

label {
  color: grey;
  font-size: 18px;
  display: inline-block;
  margin-bottom: 5%;
  margin-left: 3%;
}
/*# sourceMappingURL=reservationform.css.map */
.hero-section img {
  width: 100%;
  max-height: 55em;
}
.hero-section {
  background-color: rgb(196, 162, 117);
}
.hero-logo {
  color: rgb(255, 255, 255);
  font-family: "Montez", cursive;
  font-size: 85px;
}
.hero-phrase {
  color: rgb(196, 162, 117);
  font-size: 26px;
  font-family: "Playfair Display", serif;
}
.hero-text {
  margin-top: 10%;
  text-align: center;
  height: auto;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  display: block;
  position: absolute;
}
.hero-btn {
  padding: 5%;
  margin: auto;
  height: 70px;
  width: 65%;
}
@media only screen and (max-width: 1200px) {
  .hero-btn {
    padding: 5%;
    margin: auto;
    height: 70px;
    width: 89%;
  }
  .hero-text {
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  .hero-logo {
    font-family: "Montez", cursive;
    font-size: 75px;
  }
}

@media only screen and (max-width: 1050px) {
  .hero-phrase {
    font-size: 27px;
    font-family: "Playfair Display", serif;
  }
  .hero-text {
    margin-top: 0px;
    width: 100%;
  }
  .hero-logo {
    margin-top: 7%;
    font-size: 78px;
  }
  .hero-btn {
    width: 63%;
  }
}


@media only screen and (max-width: 600px) {
  .hero-logo {
    font-size: 55px;
    margin-top: 0%;
    margin-bottom: 7%;
  }
  .hero-text {
    margin-top: 8%;
  }
  .hero-btn {
    padding: 0%;
  }
}
.items-container{
    display: flex;
    margin: auto;
    padding: 1%;
}
.menu-container {
    margin: 0 0 5em 0;
}
.left-col {
    width: 90%;
    border-bottom: solid 1px black;
}
.right-col {
    width: 10%;
    border-bottom: solid 1px black;
    margin: 3em 0 0 0;
    text-align:right;
}
.menu-title {
    width: 50%;
    margin: auto;
}
.menu-content h1 {
    margin: 3em 0 2em 0;
    text-align: center;
    font-family: 'Montez', cursive;
    font-size: 42px;
    -webkit-text-decoration: underline rgb(196, 162, 117);
            text-decoration: underline rgb(196, 162, 117);
}
.section-title {
    margin: 2em 0 1em 0;
    font-family: 'Playfair Display', serif;
    color:rgb(196, 162, 117);
}
h3 {
    font-family: 'Playfair Display', serif;
}

@media only screen and (max-width: 1060px) {
    .menu-wrapper {
        margin:0;
        border: solid 1px rgba(138, 15, 36, 0);
    }
}

@media only screen and (max-width: 685px) {
    .right-col {
        width: 10%;
        border-bottom: solid 1px black;
        margin: 3em 0 0 0;
        text-align:right;
    }
}
.frame img {
  max-height: 55em !important;
  margin: 0;
}

.frame {
  margin: auto;
  width: 65%;
  padding: 2%;
  padding-bottom: 10em !important;
}

@media only screen and (max-width: 860px) {
  .frame {
    padding-bottom: 11em !important;
    padding-top: 10em !important;
    width: 80%;
  }
}
/*# sourceMappingURL=Gallery.css.map */
.order-wrapper {
  padding: 3%;
}

h1 {
  text-align: center;
  font-family: 'Montez', cursive;
  font-size: 42px;
  color: #c4a275;
  padding-bottom: 3%;
  -webkit-text-decoration: underline #c4a275;
          text-decoration: underline #c4a275;
}
/*# sourceMappingURL=orderpage.css.map */
