@import url("https://fonts.googleapis.com/css2?family=Montez&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");

.hero-section img {
  width: 100%;
  max-height: 55em;
}
.hero-section {
  background-color: rgb(196, 162, 117);
}
.hero-logo {
  color: rgb(255, 255, 255);
  font-family: "Montez", cursive;
  font-size: 85px;
}
.hero-phrase {
  color: rgb(196, 162, 117);
  font-size: 26px;
  font-family: "Playfair Display", serif;
}
.hero-text {
  margin-top: 10%;
  text-align: center;
  height: auto;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  display: block;
  position: absolute;
}
.hero-btn {
  padding: 5%;
  margin: auto;
  height: 70px;
  width: 65%;
}
@media only screen and (max-width: 1200px) {
  .hero-btn {
    padding: 5%;
    margin: auto;
    height: 70px;
    width: 89%;
  }
  .hero-text {
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  .hero-logo {
    font-family: "Montez", cursive;
    font-size: 75px;
  }
}

@media only screen and (max-width: 1050px) {
  .hero-phrase {
    font-size: 27px;
    font-family: "Playfair Display", serif;
  }
  .hero-text {
    margin-top: 0px;
    width: 100%;
  }
  .hero-logo {
    margin-top: 7%;
    font-size: 78px;
  }
  .hero-btn {
    width: 63%;
  }
}


@media only screen and (max-width: 600px) {
  .hero-logo {
    font-size: 55px;
    margin-top: 0%;
    margin-bottom: 7%;
  }
  .hero-text {
    margin-top: 8%;
  }
  .hero-btn {
    padding: 0%;
  }
}